import React, { Fragment, createRef } from 'react';
import { CommonList } from 'Components';
import Preloader from 'Components/Preloader';
import { helpers, components } from 'Common';
import { MembershipCartForm } from './membership_cart_form';
import { ShirtCartRows } from './shirt_cart_rows';
import { toMoneyFormat } from 'Helpers/toMoneyFormat';
import { PurchaseForUserForm } from './purchase_for_user_form';
import { DISPLAY_DATE_FORMAT } from 'Constants/dateTime';
import moment from 'moment';
import classnames from 'classnames';
import { notifyOnFailedResponse } from 'Helpers/notifications';

const { Button } = components;
const { removeFromArray, findItemIndexByKeyInArray, findItemByKeyInArray, AJAX } = helpers;
export class RegistrationDonation extends CommonList {

  shirtsList = createRef();

  state = {
    ...this.state,
    purchased_items: [],
    canSubmit: false,
    filter: {
      tournaments: this.props.tournaments[0].id
    }
  }

  addToCart = (item) => {
    const purchased_items = [...this.state.purchased_items, item]
    this.setState({
      purchased_items,
    }, this.changeCanSubmit);
  }

  changeCanSubmit = () => {
    const { purchased_items } = this.state;
    const shirts = this.serializedShirts;

    this.setState({
      canSubmit: (!!shirts.length || !!purchased_items.length),
    });
  }

  removeFromCart = (item_id) => {
    let { purchased_items } = this.state;
    const itemIndex = findItemIndexByKeyInArray(purchased_items, item_id, 'item_id');
    purchased_items = removeFromArray(purchased_items, itemIndex);
    this.setState({
      purchased_items,
    }, this.changeCanSubmit);
  }

  handlePay = () => {
    const { order_url } = this.props;
    const { purchased_items } = this.state;

    AJAX.post(order_url, {
      body: {
        order: {
          purchased_items: [
            ...purchased_items,
            ...this.serializedShirts,
          ],
        }
      }
    }).then(res => {
      if(res.status === 'error') {
        notifyOnFailedResponse(403, res.message);
      }
    })
  }

  get serializedShirts() {
    if (!this.shirtsList.current) {
      return []
    }
    const shirts = this.shirtsList.current.state.shirts;

    const filteredShirts = shirts.filter((item) => !!item.item_id && item);

    return filteredShirts.map(({ item_id, quantity, size, style }) => {
      return {
        item_id,
        quantity: Number(quantity),
        options: {
          size, style
        }
      }
    });
  }

  fetchData = () => {
    const { tournaments } = this.state.filter
    const selectedTournament = this.props.tournaments.find(tournament => tournament.id === tournaments);

    if (selectedTournament && selectedTournament.locked && !this.props.is_current_user_admin) {
      this.setState({ items: [], loading: false });
      return;
    }

    const filter = {
      tournaments: tournaments === 'all' ? this.props.tournaments.map(({ id }) => id) : [tournaments]
    }

    super.fetchData({ filter });
  }

  handleChangeTournamentFilter = (id) => {
    return () => {
      this.handleChangeFilter('tournaments', id);
    }
  }

  isItemInCart = (itemId) => {
    let { purchased_items } = this.state;
    return !!findItemByKeyInArray(purchased_items, itemId, 'item_id');
  }

  getCardStyles = ({package_group_color}) => {
    return !!package_group_color 
      ? {
          outline: '1px solid black',
          border: `5px solid ${package_group_color}` 
        }
      : null;
  }

  render() {
    const { name, is_current_user_admin, users, assign_for_user_url } = this.props;
    const { items, loading, shirts, canSubmit, purchased_items } = this.state;

    return (
      <Fragment>
        <h1 className="system__title system__title--with-button">
          {name}
          <Button className="system__title-link button-wrapper__button--auto-width"
            onClick={this.handlePay} disabled={!canSubmit}>
            Donate
          </Button>
        </h1>
        <div className="complete-mark mb-20" style={{ display: 'block', }}>
          <div className="complete-mark__block complete-mark__block--warning" style={{ borderRadius: '4px' }}>
            <div className="complete-mark__text">
              <div><span className="red">Paying By Check?</span></div>
              <div>Prior to registration please contact:</div>
              <div>
                For <strong>ABQ</strong> Bre Claycamp
                at <a className="link" href="mailto:Bre.Claycamp@wnco.com">Bre.Claycamp@wnco.com</a>
              </div>
              <div>
                For <strong>PHX</strong> Don Roberts
                at <a className="link" href="mailto:Don.Roberts@wnco.com">Don.Roberts@wnco.com</a>
              </div>
              <div className='mt-10'>Checks made payable to: “Golf Fore Charity”</div>
            </div>
          </div>
        </div>

        {is_current_user_admin && (
          <PurchaseForUserForm api_url={assign_for_user_url} users={users}
            purchased_memberships={purchased_items} purchased_shirts={this.serializedShirts}
            disabled={!canSubmit} />
        )}

        <div className="row">
          {this.props.tournaments.map(({ name, id, date, description, locked }) => (
            <div key={id} className="row__column column">
              <div className={classnames('picker-item item-card item-card--full_height', {
                'picker-item--active': this.state.filter.tournaments === id
              })} onClick={this.handleChangeTournamentFilter(id)}>
                <p className="picker-item__title">{name}</p>
                <p className="picker-item__info">{moment(date).format(DISPLAY_DATE_FORMAT)}</p>
                <p className="picker-item__description">{description}</p>
                {locked && (
                  <p className="picker-item__locked-message" style={{ color: 'red', fontWeight: 'bold' }}>
                    Locked
                  </p>
                )}
              </div>
            </div>
          ))}
        </div>
        {!!loading && (<Preloader />)}
        {!loading && (
          <div className="row">
            {items.map(item => (
              <Fragment key={item.id}>
                {item.type !== 'Shirt' && (
                  <div className="row__column column">
                    <div className="item-card item-card--full_height" 
                      style={this.getCardStyles(item)}
                    >
                      <div className="item-card__header">
                        <p className="item-card__name">{item.name}</p>
                        <p className="item-card__info">
                          Tournament: <span>{item.tournament}</span>
                        </p>
                        <p className="item-card__info">
                          Sponsorship Package: <span>{toMoneyFormat(item.price)}</span>
                        </p>
                        <p className="item-card__info">
                          Available Quantity: <span>{item.quantity}</span>
                        </p>
                        <p className="item-card__description">{item.description}</p>
                      </div>
                      <div className="item-card__footer">
                        <MembershipCartForm itemId={item.id} availableQuantity={item.quantity}
                          addToCart={this.addToCart} removeFromCart={this.removeFromCart}
                          isItemInCart={this.isItemInCart(item.id)} />
                      </div>
                    </div>
                  </div>
                )}
                {item.type === 'Shirt' && (
                  <div className="row__column row__column--half_width column">
                    <div className="item-card item-card--full_height">
                      <p className="item-card__name">{item.name}</p>
                      <p className="item-card__info">
                        Tournament: <span>{item.tournament}</span>
                      </p>
                      <p className="item-card__info">
                        Sponsorship Package: <span>{item.price}</span>
                      </p>
                      <p className="item-card__info">
                        Available Quantity: <span>{item.quantity}</span>
                      </p>
                      <p className="item-card__description">{item.description}</p>
                      <ShirtCartRows ref={this.shirtsList} itemId={item.id}
                        availableQuantity={item.quantity} shirts={shirts}
                        options={item.options} changeCanSubmit={this.changeCanSubmit} />
                    </div>
                  </div>
                )}

              </Fragment>
            ))}
          </div>
        )}
        <div className="button-wrapper">
          <Button onClick={this.handlePay} disabled={!canSubmit}>
            Donate
          </Button>
        </div>
      </Fragment>
    );
  }
}
